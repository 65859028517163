import { Box, Flex } from '@rebass/grid'
import { Link, graphql } from 'gatsby'
import React from 'react'
import { theme } from '../util/style'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import BlogItem from '../components/blog-page/PageBlog/BlogItem'
import SEO from '../components/seo'

const articles = ({ data }) => {
  let postCollection = data?.allContentfulOptions?.edges[0].node.articlesOrder

  postCollection = postCollection?.filter(
    (node) =>
      node.slug !== 'sb-dont-delete-placeholder' &&
      node.slug !== 'sb-dont-delete-related-post' &&
      node.slug !== 'test-entry'
  )

  return (
    <>
      <SEO title="Articles" />
      <Box px={2}>
        <Flex
          width={1}
          mb={['52px', '123px']}
          justifyContent={'space-between'}
          flexDirection={['column', 'row']}
        >
          <PageHeading mt={['35px', '85px']} as="h1" color={theme.colors.grey1}>
            Insights, stories and happy haus living
          </PageHeading>
          {/* <Box
          as="span"
          color={theme.colors.grey7}
          fontSize={['16px', '19px']}
          style={{
            fontFamily: theme.font.fontFamilyLight,
          }}
        >
          Categories
        </Box> */}
        </Flex>
        <BlogCollectionWrapper>
          {postCollection?.map((data) => (
            <BlogItem data={data} />
          ))}
        </BlogCollectionWrapper>
      </Box>
    </>
  )
}

export default articles
export const data = graphql`
  query {
    allContentfulOptions {
      edges {
        node {
          articlesOrder {
            id
            slug
            title
            category
            thumbnail {
              ...media
            }
          }
        }
      }
    }
  }

  fragment media on ContentfulAsset {
    fluid {
      src
    }
    fixed {
      width
      height
      src
    }
    __typename
    description
    id
    file {
      contentType
      url
    }
    gatsbyImageData(layout: FULL_WIDTH)
  }
`

const BlogCollectionWrapper = styled(Box)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  row-gap: 50px;

  @media (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const PageHeading = styled(Box)`
  font-weight: normal;
  font-family: ${theme.font.fontFamilySerifThin};
  font-size: 30px;
  line-height: 123%;
`
